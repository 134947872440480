.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.dropdown:hover > .dropdown-menu, .dropstart:hover > .dropdown-menu {
  display: block;
  /* margin-top: .125em; */
}

.dropstart:hover > .dropdown-menu {
  position: absolute;
  top: 0;
  right: 100%;
  margin-right: .125em;
}

.dropdown-item {
  font-size: 18px;
  color: white;
}

.dropdown-item:hover {
  background-color: white;
  color: black;
}

.video-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.videoTag {
  width: 100%; /* Set video width to 100% of the container */
  height: 100%; /* Set video height to 100% of the container */
  object-fit: cover; /* Cover the entire container, maintaining aspect ratio */
}

.navbar {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000; 
}

.bgColor {
  background-color: white;
  color: black;
  border-bottom: 1px solid lightgray;
}

.about-section {
  /* margin-top: 100vh; Ensure the about section appears below the video background */
  padding: 20px;
  background: linear-gradient(rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7)), url("../src/assets/about/2.jpeg") no-repeat center fixed;
  background-size: cover;
  min-height: 400px; /* Adjust the height as needed */
  color: #01013d; /* Text color */
}

.values {
  margin-top: 100vh;
  padding: 30px;
}

.aboutValues {
  padding: 30px;
}

.solar-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.career-background {
  position: relative; 
  width: 100%; /* Assuming you want the section to take the full width */
  height: 100vh; /* Assuming you want the section to take the full viewport height */
  background-image: url('../src/assets/careers/2.jpg');
  background-size: cover; /* Cover the entire area of the section */
  background-position: center; /* Center the background image */
  background-repeat: no-repeat; /* No repeating of the image */
}


.solarTag {
  width: 100%; /* Set video width to 100% of the container */
  height: 100%; /* Set video height to 100% of the container */
  object-fit: cover; /* Cover the entire container, maintaining aspect ratio */
}

.aboutSolar {
  margin-top: 100vh;
  padding: 30px;
}

.electronics-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.electronicTag {
  width: 100%; /* Set video width to 100% of the container */
  height: 100%; /* Set video height to 100% of the container */
  object-fit: cover; /* Cover the entire container, maintaining aspect ratio */
}

.aboutElectronics {
  margin-top: 100vh;
  padding: 30px;
}

.electronicServices {
  padding: 30px;
}

.solarServices {
  padding: 30px;
}

.windServices {
  padding: 30px;
}

.electricalServices {
  padding: 30px;
}

.chooseElectronic {
  padding: 30px;
}

.chooseSolar {
  padding: 30px;
}

.chooseWind {
  padding: 30px;
}

.chooseElectrical {
  padding: 30px;
}

.electrical-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.electricalTag {
  width: 100%; /* Set video width to 100% of the container */
  height: 100%; /* Set video height to 100% of the container */
  object-fit: cover; /* Cover the entire container, maintaining aspect ratio */
}

.aboutElectrical {
  margin-top: 100vh;
  padding: 30px;
}

.aboutWind {
  margin-top: 100vh;
  padding: 30px;
}

.wind-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.windTag {
  width: 100%; /* Set video width to 100% of the container */
  height: 100%; /* Set video height to 100% of the container */
  object-fit: cover; /* Cover the entire container, maintaining aspect ratio */
}

#ourServices {
  padding: 20px;
  /* background: linear-gradient(rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.4)), url("../src/assets/services/11.jpg") no-repeat center fixed; */
  /* background-size: cover; */
  /* min-height: 400px; */
  /* color: #01013d; */
}

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&display=swap');

* {
    margin: 0;
    padding: 0;
    font-family: 'Poppins', sans-serif
}

/* .container .card
{
    max-width: 300px;
    height: 215px;
    margin: 30px 10px;
    padding: 20px 15px;
    box-shadow: 0 5px 20px rgba(0,0,0,0.5);
    transition: 0.3s ease-in-out;
}
.container .card:hover
{
    height: 650px;
}
.container .card .imgContainer
{
    position: relative;
    width:250px;
    height:250px;
    top:-50px;
    left:10px;
    z-index: 1;
    box-shadow: 0 5px 20px rgba(0,0,0,0.2);
}
.container .card .imgContainer img
{
    max-width: 100%;
    border-radius: 4px;
}
.container .card .content
{
    position: relative;
    margin-top: -140px;
    padding: 10px 15px;
    text-align: center;
    color:#111;
    visibility: hidden;
    opacity: 0;
    pointer-events: none;
    transition: 0.3s ease-in-out;
}
.container .card:hover .content
{
    visibility: visible;
    opacity: 1;
    margin-top: -40px;
    transition-delay: 0.3s;
}

@media (max-width: 330px){
    .container .card .imgContainer{
        left: -2px;
    }
} */

#contactUs {
  background: linear-gradient(rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7)), url("https://images.unsplash.com/photo-1487017159836-4e23ece2e4cf?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mjd8fGNvbnRhY3QlMjB1c3xlbnwwfDB8MHx8fDA%3D") no-repeat center fixed;
  background-size: cover;
  min-height: 400px; /* Adjust the height as needed */
  color: #01013d; /* Text color */
}

.footer{
  background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url("../src/assets/footer/2.jpg") no-repeat center fixed;
  background-size: cover;
  min-height: 450px;
  /* color: #01013d; */
  font-size: 14px;
  .underline{
    position: relative;
    display:inline-block;
    &:after {
      position: absolute;
      content: '';
      display: block;
      width: 31px;
      height: 2px;
      background-color: whitesmoke;
      bottom: -10px;
     }
  }
  p{
    color: #A1AEB7;
  }
  a{
    color: #A1AEB7;
    text-decoration: none;
     transition: all 0.5s ease-in-out;
    &:hover{
      color: whitesmoke;
    }
  }
}

.page-section {
  padding: 6rem 0;
}
.page-section h2.section-heading, .page-section .section-heading.h2 {
  font-size: 2.5rem;
  margin-top: 0;
  margin-bottom: 1rem;
}
.page-section h3.section-subheading, .page-section .section-subheading.h3 {
  font-size: 1rem;
  font-weight: 400;
  font-style: italic;
  font-family: "Roboto Slab", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  margin-bottom: 4rem;
}

.timeline {
  position: relative;
  padding: 0;
  list-style: none;
}
.timeline:before {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 40px;
  width: 2px;
  margin-left: -1.5px;
  content: "";
  background-color: #e9ecef;
}
.timeline > li {
  position: relative;
  min-height: 50px;
  margin-bottom: 50px;
}
.timeline > li:after, .timeline > li:before {
  display: table;
  content: " ";
}
.timeline > li:after {
  clear: both;
}
.timeline > li .timeline-panel {
  position: relative;
  float: right;
  width: 100%;
  padding: 0 20px 0 100px;
  text-align: left;
}
.timeline > li .timeline-panel:before {
  right: auto;
  left: -15px;
  border-right-width: 15px;
  border-left-width: 0;
}
.timeline > li .timeline-panel:after {
  right: auto;
  left: -14px;
  border-right-width: 14px;
  border-left-width: 0;
}
.timeline > li .timeline-image {
  position: absolute;
  z-index: 100;
  left: 0;
  width: 80px;
  height: 80px;
  margin-left: 0;
  text-align: center;
  color: white;
  border: 7px solid #e9ecef;
  border-radius: 100%;
  background-color: #01013d;
}
.timeline > li .timeline-image h4, .timeline > li .timeline-image .h4 {
  font-size: 10px;
  line-height: 14px;
  margin-top: 12px;
}
.timeline > li.timeline-inverted > .timeline-panel {
  float: right;
  padding: 0 20px 0 100px;
  text-align: left;
}
.timeline > li.timeline-inverted > .timeline-panel:before {
  right: auto;
  left: -15px;
  border-right-width: 15px;
  border-left-width: 0;
}
.timeline > li.timeline-inverted > .timeline-panel:after {
  right: auto;
  left: -14px;
  border-right-width: 14px;
  border-left-width: 0;
}
.timeline > li:last-child {
  margin-bottom: 0;
}
.timeline .timeline-heading h4, .timeline .timeline-heading .h4 {
  margin-top: 0;
  color: inherit;
}
.timeline .timeline-heading h4.subheading, .timeline .timeline-heading .subheading.h4 {
  text-transform: none;
}
.timeline .timeline-body > ul,
.timeline .timeline-body > p {
  margin-bottom: 0;
}

@media (min-width: 768px) {
  .timeline:before {
    left: 50%;
  }
  .timeline > li {
    min-height: 100px;
    margin-bottom: 100px;
  }
  .timeline > li .timeline-panel {
    float: left;
    width: 41%;
    padding: 0 20px 20px 30px;
    text-align: right;
  }
  .timeline > li .timeline-image {
    left: 50%;
    width: 100px;
    height: 100px;
    margin-left: -50px;
  }
  .timeline > li .timeline-image h4, .timeline > li .timeline-image .h4 {
    font-size: 13px;
    line-height: 18px;
    margin-top: 16px;
  }
  .timeline > li.timeline-inverted > .timeline-panel {
    float: right;
    padding: 0 30px 20px 20px;
    text-align: left;
  }
}
@media (min-width: 992px) {
  .timeline > li {
    min-height: 150px;
  }
  .timeline > li .timeline-panel {
    padding: 0 20px 20px;
  }
  .timeline > li .timeline-image {
    width: 150px;
    height: 150px;
    margin-left: -75px;
  }
  .timeline > li .timeline-image h4, .timeline > li .timeline-image .h4 {
    font-size: 18px;
    line-height: 26px;
    margin-top: 30px;
  }
  .timeline > li.timeline-inverted > .timeline-panel {
    padding: 0 20px 20px;
  }
}
@media (min-width: 1200px) {
  .timeline > li {
    min-height: 170px;
  }
  .timeline > li .timeline-panel {
    padding: 0 20px 20px 100px;
  }
  .timeline > li .timeline-image {
    width: 170px;
    height: 170px;
    margin-left: -85px;
  }
  .timeline > li .timeline-image h4, .timeline > li .timeline-image .h4 {
    margin-top: 40px;
  }
  .timeline > li.timeline-inverted > .timeline-panel {
    padding: 0 100px 20px 20px;
  }
}

.rounded-circle {
  border-radius: 50% !important;
}

.img-fluid {
  max-width: 100%;
  height: 100%;
}

.aboutImage {
  width: 100%; /* Ensure the image fills its container */
  max-width: 600px; /* Limit the maximum width of the image */
  height: 80vh; /* Maintain aspect ratio */
  border-radius: 10px; /* Apply a slight border-radius for a softer look */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Add a subtle box shadow for depth */
  display: block; /* Ensure the image behaves as a block element */
  margin: 0 auto; /* Center the image horizontally */
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6); /* Adjust the alpha (last value) to change transparency */
}

.text-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  width: 100%;
  color: white; /* Text color */
}

.frame-1 {
  white-space: nowrap;
  overflow: hidden;    
  font-family: 'Source Code Pro', monospace;  
  /* font-size: 28px; */
  color: rgba(255,255,255,.70);
  text-align: center;
  width: 100%;
}

.frame-1 .title {
  font-size: 40px;
}

.frame-1 .sub-title {
  font-size: 20px;
}

.frame-1 .titles {
  font-size: 40px;
}

.titles {
  display: none;
}

.visible {
  display: block;
  /* Add animation or transition properties here for fading in/out */
}

/* Animation */
.frame-1 {
  animation: animated-text 4s steps(29,end) 1s 1 normal both,
             animated-cursor 600ms steps(29,end) infinite;
}

/* text animation */

@keyframes animated-text{
  from{width: 0;}
  to{width: 100%;}
}

@media screen and (max-width: 768px) {
  .frame-1 .title {
    font-size: 10px; /* Adjust font size for smaller screens */
  }

  .frame-1 .titles {
    font-size: 10px; /* Adjust font size for smaller screens */
  }

  .frame-1 .sub-title {
    font-size: 10px; /* Adjust font size for smaller screens */
  }
}

.workZaxix {
  padding: 30px;
}

.current-openings {
  padding: 30px;
}

.apply {
  padding: 30px;
}

.joinUs {
  padding: 30px;
}

.technologies-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.technologiesTag {
  width: 100%; /* Set video width to 100% of the container */
  height: 100%; /* Set video height to 100% of the container */
  object-fit: cover; /* Cover the entire container, maintaining aspect ratio */
}

.aboutTechnologies {
  /* margin-top: 100vh; */
  padding: 30px;
  /* background-color: black; */
}

.technologiesServices {
  padding: 30px;
  margin-top: 100vh;
  /* background: black; */
}

.technologiesProjects {
  padding: 30px;
  /* background-color: black; */
}

.aboutPart1 {
  /* margin-top: 100vh; Ensure the about section appears below the video background */
  padding: 20px;
  background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url("../src/assets/about/2.jpeg") no-repeat center fixed;
  background-size: cover;
  min-height: 400px; /* Adjust the height as needed */
  color: white; /* Text color */
  @media screen and (max-width: 768px) {
    background-position: center;
    background-attachment: scroll;
    min-height: auto; /* Adjust height for mobile */
  }
}

.aboutPart2 {
  padding: 30px;
}

.aboutPart3 {
  padding: 30px;
}

.aboutPart3_1 {
  /* margin-top: 100vh; Ensure the about section appears below the video background */
  padding: 20px;
  background: linear-gradient(rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7)), url("../src/assets/services/1.jpeg") no-repeat center fixed;
  background-size: cover;
  min-height: 400px; /* Adjust the height as needed */
  /* color: white; Text color */
}

.aboutPart3_2 {
  /* margin-top: 100vh; Ensure the about section appears below the video background */
  padding: 20px;
  background: linear-gradient(rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7)), url("../src/assets/services/2.jpg") no-repeat center fixed;
  background-size: cover;
  min-height: 400px; /* Adjust the height as needed */
  /* color: white; Text color */
}

.aboutPart3_3 {
  /* margin-top: 100vh; Ensure the about section appears below the video background */
  padding: 20px;
  background: linear-gradient(rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7)), url("../src/assets/services/3.jpg") no-repeat center fixed;
  background-size: cover;
  min-height: 400px; /* Adjust the height as needed */
  /* color: white; Text color */
}

.aboutPart3_4 {
  /* margin-top: 100vh; Ensure the about section appears below the video background */
  padding: 20px;
  background: linear-gradient(rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7)), url("../src/assets/services/4.jpeg") no-repeat center fixed;
  background-size: cover;
  min-height: 400px; /* Adjust the height as needed */
  /* color: white; Text color */
}

.aboutPart3_5 {
  /* margin-top: 100vh; Ensure the about section appears below the video background */
  padding: 20px;
  background: linear-gradient(rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7)), url("../src/assets/services/5.jpg") no-repeat center fixed;
  background-size: cover;
  min-height: 400px; /* Adjust the height as needed */
  /* color: white; Text color */
}

.aboutPart3Image {
  width: 100%; /* Ensure the image fills its container */
  max-width: 600px; /* Limit the maximum width of the image */
  height: 40vh; /* Maintain aspect ratio */
  border-radius: 10px; /* Apply a slight border-radius for a softer look */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Add a subtle box shadow for depth */
  display: block; /* Ensure the image behaves as a block element */
  margin: 0 auto; /* Center the image horizontally */
}

.ourValues {
  padding: 30px;
}

.sustainabilityPart1 {
  /* margin-top: 100vh; Ensure the about section appears below the video background */
  padding: 20px;
  background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url("../src/assets/sustainability/3.jpg") no-repeat center fixed;
  background-size: cover;
  min-height: 400px; /* Adjust the height as needed */
  color: white; /* Text color */
  @media screen and (max-width: 768px) {
    background-position: center;
    background-attachment: scroll;
    min-height: auto; /* Adjust height for mobile */
  }
}

.sustainabilityPart2 {
  padding: 30px;
}

.sustainabilityPart3 {
  padding: 30px;
}

.sustainabilityPart3Image {
  width: 100%; /* Ensure the image fills its container */
  max-width: 600px; /* Limit the maximum width of the image */
  height: 40vh; /* Maintain aspect ratio */
  border-radius: 10px; /* Apply a slight border-radius for a softer look */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Add a subtle box shadow for depth */
  display: block; /* Ensure the image behaves as a block element */
  margin: 0 auto; /* Center the image horizontally */
}

.sustainabilityPart3_1 {
  /* margin-top: 100vh; Ensure the about section appears below the video background */
  padding: 20px;
  background: linear-gradient(rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7)), url("../src/assets/sustainability/4.jpg") no-repeat center fixed;
  background-size: cover;
  min-height: 400px; /* Adjust the height as needed */
  /* color: white; Text color */
}

.sustainabilityPart3_2 {
  /* margin-top: 100vh; Ensure the about section appears below the video background */
  padding: 20px;
  background: linear-gradient(rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7)), url("../src/assets/sustainability/5.jpg") no-repeat center fixed;
  background-size: cover;
  min-height: 400px; /* Adjust the height as needed */
  /* color: white; Text color */
}

.sustainabilityPart3_3 {
  /* margin-top: 100vh; Ensure the about section appears below the video background */
  padding: 20px;
  background: linear-gradient(rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7)), url("../src/assets/sustainability/1.jpg") no-repeat center fixed;
  background-size: cover;
  min-height: 400px; /* Adjust the height as needed */
  /* color: white; Text color */
}

.sustainabilityPart3_4 {
  /* margin-top: 100vh; Ensure the about section appears below the video background */
  padding: 20px;
  background: linear-gradient(rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7)), url("../src/assets/sustainability/6.jpg") no-repeat center fixed;
  background-size: cover;
  min-height: 400px; /* Adjust the height as needed */
  /* color: white; Text color */
}

.sustainabilityPart3_5 {
  /* margin-top: 100vh; Ensure the about section appears below the video background */
  padding: 20px;
  background: linear-gradient(rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7)), url("../src/assets/sustainability/7.jpg") no-repeat center fixed;
  background-size: cover;
  min-height: 400px; /* Adjust the height as needed */
  /* color: white; Text color */
}

.sustainabilityPart4 {
  padding: 30px;
}

/* CSS for Static Shadow Zoom Animation */
.sustainabilityPart3 {
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.5); /* Initial shadow effect */
  transition: box-shadow 0.5s ease-in-out; /* Smooth transition for the shadow effect */
}

/* CSS for Static Shadow Zoom Animation */
.aboutPart3 {
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.5); /* Initial shadow effect */
  transition: box-shadow 0.5s ease-in-out; /* Smooth transition for the shadow effect */
}

.clients-section {
  padding: 30px;
}

.scroll-wrapper {
  width: 100%;
  overflow: hidden; /* Hide the scrollbar */
}

.clients-scroll-container {
  display: flex;
  width: 200%; /* Make the container twice as wide to accommodate the duplicated content */
  animation: scroll 20s linear infinite; /* Adjust animation duration as needed */
}

.clients-scroll-container img {
  max-width: 100%;
  margin-right: 20px; /* Adjust spacing between images as needed */
}

@media only screen and (max-width: 768px) {
  .clients-scroll-container {
    width: auto; /* Set width to auto for mobile devices */
    flex-wrap: nowrap; /* Prevent images from wrapping */
    overflow-x: auto; /* Enable horizontal scrolling on small screens */
    -webkit-overflow-scrolling: touch; /* Enable smooth scrolling on iOS */
    animation: none; /* Disable animation on small screens */
  }
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}

.ourGroupWebsites {
  padding: 30px;
}

.service-item {
  display: inline-block;
  transition: transform 0.5s;
}

.service-item:hover {
  transform: scale(1.2);
}

.nav-link:hover {
  text-decoration-line: underline;
  text-decoration-color: darkblue;
  text-decoration-thickness: 2px;
}

.card {
  width: 14rem;
  /* transition: width 0.3s ease; Smooth transition for width change */
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.3); /* Initial shadow effect */
  transition: box-shadow 0.5s ease-in-out; /* Smooth transition for the shadow effect */
}

.card-container .card:hover {
  width: 20rem; /* Increase the width of the hovered card */
  height: 20rem;
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.5); /* Initial shadow effect */
  transition: box-shadow 0.5s ease-in-out; /* Smooth transition for the shadow effect */
}

.background-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
  opacity: 0.1;
}

.video-container {
  position: relative;
  overflow: hidden;
  height: 80vh;
}

.card {
  background-color: rgba(255, 255, 255, 0.8);
}

@media only screen and (max-width: 768px) {
  .card-container {
      overflow-x: auto; /* Enable horizontal scrolling */
      white-space: nowrap; /* Prevent cards from wrapping */
  }
  .card {
      display: inline-block; /* Ensure cards are displayed horizontally */
      vertical-align: top; /* Align cards at the top */
  }
}

.electronicProject1 {
  /* margin-top: 100vh; Ensure the about section appears below the video background */
  padding: 20px;
  background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url("../src/assets/electronicServices/5.jpeg") no-repeat center fixed;
  background-size: cover;
  min-height: 400px; /* Adjust the height as needed */
  color: white; /* Text color */
  @media screen and (max-width: 768px) {
    background-position: center;
    background-attachment: scroll;
    min-height: auto; /* Adjust height for mobile */
  }
}

.electricalProject1 {
  /* margin-top: 100vh; Ensure the about section appears below the video background */
  padding: 20px;
  background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url("../src/assets/services/4.jpeg") no-repeat center fixed;
  background-size: cover;
  min-height: 400px; /* Adjust the height as needed */
  color: white; /* Text color */
  @media screen and (max-width: 768px) {
    background-position: center;
    background-attachment: scroll;
    min-height: auto; /* Adjust height for mobile */
  }
}

.technologiesProject1 {
  /* margin-top: 100vh; Ensure the about section appears below the video background */
  padding: 20px;
  background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url("../src/assets/services/5.jpg") no-repeat center fixed;
  background-size: cover;
  min-height: 400px; /* Adjust the height as needed */
  color: white; /* Text color */
  @media screen and (max-width: 768px) {
    background-position: center;
    background-attachment: scroll;
    min-height: auto; /* Adjust height for mobile */
  }
}

#aboutElectronics1 {
  padding: 20px;
  background: linear-gradient(rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8)), url("../src/assets/electronicServices/5.jpeg") no-repeat center fixed;
  background-size: cover;
  min-height: 400px; /* Adjust the height as needed */
  color: black; /* Text color */
  @media screen and (max-width: 768px) {
    background-position: center;
    background-attachment: scroll;
    min-height: auto; /* Adjust height for mobile */
  }
}

#aboutElectronics2 {
  padding: 20px;
  background: linear-gradient(rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.6)), url("../src/assets/electronicServices/5.jpeg") no-repeat center fixed;
  background-size: cover;
  min-height: 400px; /* Adjust the height as needed */
  color: black;
  @media screen and (max-width: 768px) {
    background-position: center;
    background-attachment: scroll;
    min-height: auto; /* Adjust height for mobile */
  }
}

.electronicProduct {
  padding: 30px;
}

.electronicProject2 {
  padding: 30px;
}

.cardHover {
  transition: transform 0.2s ease;
}

.cardHover:hover {
  transform: scale(0.95);
}

#aboutElectrical1 {
  padding: 20px;
  background: linear-gradient(rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8)), url("../src/assets/services/4.jpeg") no-repeat center fixed;
  background-size: cover;
  min-height: 400px; /* Adjust the height as needed */
  color: black; /* Text color */
  @media screen and (max-width: 768px) {
    background-position: center;
    background-attachment: scroll;
    min-height: auto; /* Adjust height for mobile */
  }
}

#aboutElectrical2 {
  padding: 20px;
  background: linear-gradient(rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.6)), url("../src/assets/services/4.jpeg") no-repeat center fixed;
  background-size: cover;
  min-height: 400px; /* Adjust the height as needed */
  color: black;
  @media screen and (max-width: 768px) {
    background-position: center;
    background-attachment: scroll;
    min-height: auto; /* Adjust height for mobile */
  }
}

.electricalProduct {
  padding: 30px;
  /* background: linear-gradient(to bottom right, rgba(255, 255, 255, 0.5), #01013d); */
  background: linear-gradient(to bottom right, rgba(255, 255, 255, 0), rgba(1, 1, 61, 0.4));

}

#scrollableRow .card {
  border: 1px solid #ddd;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.btn-scroll {
  cursor: pointer;
  transition: transform 0.3s ease;
}

.btn-scroll:hover {
  transform: scale(1.2);
}

.buttons-container {
  display: flex;
  justify-content: center;
}

.technologiesProject2 {
  padding: 30px;
}

.solarProject1 {
  /* margin-top: 100vh; Ensure the about section appears below the video background */
  padding: 20px;
  background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url("../src/assets/services/2.jpg") no-repeat center fixed;
  background-size: cover;
  min-height: 400px; /* Adjust the height as needed */
  color: white; /* Text color */
  @media screen and (max-width: 768px) {
    background-position: center;
    background-attachment: scroll;
    min-height: auto; /* Adjust height for mobile */
  }
}

.solarProject2 {
  padding: 30px;
}

.mobileLogo {
  font-size: 28px;
  color: #01013d;
  @media screen and (max-width: 768px) {
    font-size: 10px;
  }
}

.mobileCertificate {
  height: 10vh;
  width: 16vh;
  @media screen and (max-width: 768px) {
    height: 5vh;
    width: 8vh;
  }
}

.solarProjectMobile {
  max-height: 70vh;
  @media screen and (max-width: 768px) {
    max-height: 50vh;
    width: 50vh;
  }
}